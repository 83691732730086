@import '../../../../styles/variables.less';

@color-chat-participant-add-button-border: @color-dark-gray;
@color-chat-participant-add-button-border-hover: @color-marley-blue;
@color-chat-participant-add-button-text: @color-dark-gray;
@color-chat-participant-add-button-text-hover: @color-marley-blue;

.chat-participant-add-button {
  align-items: baseline;

  &:hover {
    color: @color-chat-participant-add-button-text-hover;
    background-color: transparent !important;

    .chat-participant-add-circle {
      border-color: @color-chat-participant-add-button-border-hover;
      border-style: solid;
    }
  }
}

.chat-participant-add-circle {
  border: 2px dashed @color-chat-participant-add-button-border;
  border-radius: 100%;
  display: block;
  font-size: 22px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 26px;
}