@import "../../../../styles/variables";

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.loading {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border: 2px solid transparent;
  border-top-color: rgb(55, 234, 196);
  border-bottom-color: rgb(55, 234, 196);
  border-radius: 50%;
  animation: rotate 2s linear infinite;
  margin-bottom: 10px;

  svg {
    display: none;
  }
}

.loading:after,
.loading:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: rgb(55, 234, 196);
}

.loading:after {
  top: 1px;
  right: -7px;
  transform: rotate(135deg);
}

.loading:before {
  top: 11px;
  left: -7px;
  transform: rotate(-45deg);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.table-col-assigned {
  max-width: 162px;
  width: 162px;
  margin-right: 16px;

  .table-cell-label-container {
    width: 100%;
  }
}

.table-col-actions {
  flex: 0 0 115px !important;
}

.sort-table {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .sort-table-data-inner {
    padding-right: 5px;
  }
}

.none-found-notification,
.loading-notification {
  text-align: center;
  height: 197px;
  width: 99%;
  border-radius: 8px;
  background-color: @color-lightest-gray;
  box-shadow: 0 0 4px 0 @color-gray;
  color: @color-gray;
  font-size: @font-size-biggest;
  font-weight: @font-weight-bold;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @color-white;
  margin: 2px;
  flex-direction: column;
}

.sort-header {
  // display: block;
}

.table-col:first-child {
  padding-left: 8px
}

.sort-table-data {
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  flex: 1;
}

/* .sort-header>th {
  color: @color-black;
  font-size: @font-size-big;
  line-height: 18px;
  font-weight: @font-weight-bold;
  min-width: 1px;
}

.sort-table .table-responsive {
  position: relative;
  width: 100%;
  border: 1px solid transparent;
}

.table>thead>tr.sort-header>th {
  border-bottom: 0;
  padding-left: 0px !important;
}

.table>tbody>tr.sort-row>td {
  border-top: 0;
  vertical-align: middle;
  padding: 0;

  &.job-id {
    padding-left: 10px;
  }

  &.lead-col-open, &.lead-col-closed {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0;
    border-right:none;
    background-color: transparent;
    width: 10px;
  }

  &.lead-col-open + td, &.lead-col-closed + td {
    padding-left: 10px;
  }

  &.lead-col-open {
    background-color: @color-green;
  }

  &.lead-col-closed {
    background-color: @color-light-grey;
  }
}

.table>tbody>tr.sort-row-open>td {
  background-color: @color-lighter-blue;
}
.table>tbody>tr.sort-row-closed>td {
  background-color: @color-lighter-grey;
  font-weight: normal;
  color: @font-color-grey-three;
} */