.radio-wrapper {
  display: block;
  margin-bottom: 15px;
}

.radioHorizontal {
  display: flex;
  justify-content: center;

  .radio-wrapper {
    margin-left: 30px;
  }
}

label.radio-container {
  display: inline;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  line-height: 16px;
  font-size: 14px !important;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  margin-right: 40px !important;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #5e7388;
  border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden disabled) */
.disabled.radio-checkmark {
  border: 1px solid #cfd7df;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked. ~ is a way to invoke styling on sibling elements */
.radio-container input:checked~.radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3bc7eb;
}

.radio-container .disabled.radio-checkmark:after {
  background: #cfd7df;
}