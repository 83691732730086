@import '../../../../../styles/variables.less';

.timeout-container {
  width: 30%;

  .dropdown {
    .timeout-dropdown {
      padding-left: 5px !important;
      width: fit-content !important;
      min-width: 104px;
      border-radius: 3px;
      border: 1px solid #8796a6;
    }

    .dropdown-menu,
    .dropdown-menu-right {
      min-width: 104px !important;
      max-width: 104px !important;
      padding: 16px 7px bottom 2px !important;
      border-radius: 3px;
      height: 225px;
      left: 0;
      right: 0;
      overflow: scroll;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      li a {
        line-height: 2.5;
      }
    }
  }
}