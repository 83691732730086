@import "../../../../styles/variables";

.in-current-conversation {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  .title {
    padding-bottom: 2px;
    font-size: 20px;
    font-weight: 600;
  }

  .subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #16212E;
    padding-top: 10px;
  }

  .panel {
    position: absolute;
    right: 16px;
    left: 16px;
    height: auto;
    margin-top: -8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .list-group .user-row .add-button {
    display: none;
  }

  .user-row:hover {
    .add-button {
      display: block;
    }
  }

  .search-and-participants {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    min-height: 70%;

    .participants {
      overflow-y: scroll;
      max-height: 30vh;
      min-height: 30vh;
      overflow-x: hidden;
    }

    .search-dropdown .marley-dropdown-caret {
      z-index: 1;
    }
  }

  .user-row {
    height: 55px;
    align-items: center;

    &:hover {
      background-color: #F5F5F6;
    }

    .marley-button {
      height: 36px;
    }

    .assigned-operator-role {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #5E7388;
    }

    .user-info {
      padding-left: 20px;

      .user-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #16212E;
        white-space: normal;
      }

      .user-role {
        font-size: 12px;
        line-height: 16px;
        color: #16212E;
      }
    }

    .add-button {
      display: none;

      button {
        min-width: 60px;
      }
    }

    .generic-user-photo,
    .user-photo {
      margin-top: 3px;
    }

    &.unassigned-user-row {
      .user-photo2 .user-color {
        background-color: #D8D8D8
      }
    }
  }

  .search-dropdown {
    .marley-dropdown-container-inner {
      @media only screen and (max-width: @mobile-width) {
        z-index: 99999999999999;
        position: fixed;
        max-height: 60vh;
      }

      width: 100%;
      min-height: 250px;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 45vh;
    }
  }

  .search-bar {
    margin-top: 11px;
  }

  .list-group {
    // height: 255px;
    margin-bottom: 0px;
    overflow: auto;
  }

  .pinned-header {
    font-size: @font-size-small;
    text-transform: uppercase;
    font-weight: @font-weight-bold;
    display: flex;
    align-items: center;
    padding: 3px 0px 0px;

    .pin-icon {
      svg {
        width: 14px;
        height: 14px;
      }

      display: inline-block;
    }

    .pin-title {
      padding-left: 2px;
    }
  }

  .filter-list-filter-border:before {
    content: none;
  }

  .list-group .user-list {
    .user-row:hover {
      background-color: #f5f5f5;
    }

    .add-button {
      display: block !important;
    }
  }

  .list-group .select-list-item-info {
    .user-row {
      background-color: #F5F5F6;
    }

    .add-button {
      display: block !important;
      text-align: right;
    }
  }
}