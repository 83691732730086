@import '../../../../../styles/variables.less';

.containerHidden {
  display: none;
}

.container {
  width: 375px !important;
  min-width: 375px !important;
  padding-right: 0 !important;

  header {
    font-weight: 500 !important;
    font-size: @font-size-normal-rem !important;
  }

  .label {
    font-weight: 400;
    font-size: @font-size-medium-rem;
    color: @font-color-shark;
    padding-right: 5px !important;
    padding-bottom: 12px !important;
  }

  :global(.divider) {
    margin: 0;
    z-index: -1;
    margin-top: 0;
    height: 0;
    padding-bottom: 15px;
  }
}

.content {
  section {
    margin-top: 32px;
  }

  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: @mobile-width) {
  .container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    box-shadow: none !important;
  }

  .content {
    width: 320px;
    justify-self: center;
  }

  .assigneeSection {
    order: -1; // Move this to the first position in the flex container
  }
}

.labelActions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
  }

  button {
    border: 0;
    background: transparent;
    color: @color-dark-gray;
    font-size: @font-size-medium-rem;
  }
}

.checkBox {
  justify-content: flex-start !important;
  font-weight: 400;

  label {
    margin-left: 0 !important;
    cursor: pointer;
    margin-right: 0 !important;
  }

  span {
    color: @font-color-shark;
    font-weight: 400;
    margin-left: 5px;
  }

  div {
    padding: 1px;
  }
}

.radioGroup {
  label {
    font-weight: 400;
    margin-bottom: 0;
  }
}

.autoWidth {
  width: auto !important;
}

.toggle {
  button {
    padding: 15px 0 !important;
    height: 32px;
  }
}

.assigneeTrigger,
.assigneeDropdown,
.toggle {
  width: 334px !important;
}

.footer {
  >div {
    display: none;
  }

  position: initial !important;
  padding-right: 20px;

  button {
    min-width: 96px;
  }
}

.body {
  height: calc(100vh - 230px) !important;
  overflow-x: hidden !important;
  padding-right: 20px;
}

.mentionsInfo {
  font-size: @font-size-medium-rem;
  color: @color-dark-gray;
  font-weight: 400;
  margin: 16px;
}

.filterCount {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  p {
    margin-bottom: 0;
  }

  button {
    height: 20px;
    margin-right: 5px;
  }
}

.mobileAssigneeDropdowns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}